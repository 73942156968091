import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, keyframes, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC, useEffect, useState } from "react";
import { useNikolausaktionBooking } from "../../context/NikolausaktionBookingContext";
import { NikolausaktionBookingDTO, NikolausChild } from "../../models";
import { Tab } from "react-bootstrap";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const NikolausBookings: FC = () => {

    const [allBookings, setAllBookings] = useState<NikolausaktionBookingDTO[]>([])
    const nikolausAktionBookingContext = useNikolausaktionBooking();
    const [emailText, setEmailText] = useState<string>("");
    const [bookingToDeleteId, setBooingToDeleteId] = useState<string>("")
    const [open, setOpen] = useState(false);
    const [openChild, setOpenChild] = useState(false)
    const [showChild, setShowChild] = useState<NikolausChild[]>([])

    const handleClickOpen = (id: string) => {
        setOpen(true);
        setBooingToDeleteId(id)
    };
    const handleClose = () => {
        setOpen(false);
        setEmailText("")
        setBooingToDeleteId("")
    };

    const sendCancelEmail = () => {
        nikolausAktionBookingContext.deleteNikolausaktionBookingAsAdmin(bookingToDeleteId, emailText).then(() => {
            nikolausAktionBookingContext.getAllNikolausaktionBookings().then(result => {
                setAllBookings(result)
            })
            handleClose()
        })
    }

    const showChildren = (children: NikolausChild[]) => {
        setOpenChild(true)
        setShowChild(children);
    }

    const handleCloseChild = () => {
        setOpenChild(false)
        setShowChild([])
    }

    useEffect(() => {
        nikolausAktionBookingContext.getAllNikolausaktionBookings().then(result => {
            setAllBookings(result)
        })
    }, [])


    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Adresse</TableCell>
                            <TableCell align="right">Telefonnummer</TableCell>
                            <TableCell align="right">E-Mail</TableCell>
                            <TableCell align="right">Datum</TableCell>
                            <TableCell align="right">Uhrzeit</TableCell>
                            <TableCell align="right">Quelle</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            allBookings.length > 0 ? (
                                allBookings.map((booking, key) => {
                                    return <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        key={key}
                                    >
                                        <TableCell component="th" scope="row">{booking.firstName + ' ' + booking.lastName}</TableCell>
                                        <TableCell align="right">{booking.address.street + ' ' + booking.address.houseNumber + ', ' + booking.address.plz + ' ' + booking.address.city}</TableCell>
                                        <TableCell align="right">{booking.phoneNumber}</TableCell>
                                        <TableCell align="right">{booking.email}</TableCell>
                                        <TableCell align="right">{booking.date}</TableCell>
                                        <TableCell align="right">{booking.timeSpan}</TableCell>
                                        <TableCell align="right">{booking.contactSource}</TableCell>
                                        <TableCell align="right"><Button variant="contained" onClick={() => handleClickOpen(booking.id!)}>Stornieren</Button></TableCell>
                                        <TableCell align="right"><Button variant="contained" onClick={() => showChildren(booking.children)}>Kinder</Button></TableCell>
                                    </TableRow>
                                })
                            ) : (null)
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="deleteEvent"
                fullWidth
            >
                <DialogTitle>{"Stornieren eines Termins"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="deleteEvent" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Schreibe einen Text für die Stornier-E-Mail"
                            style={{ width: "100%" }}
                            value={emailText}
                            onChange={(value) => {
                                setEmailText(value.target.value)
                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={() => sendCancelEmail()}>Senden</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openChild}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="showChild"
                fullWidth
            >
                <DialogTitle>{"Kinder"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="showChild" sx={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            showChild.map((child, key) => {
                                return <div style={{ marginBottom: '20px' }} key={key}>
                                    <h3>{child.name}, {child.age}</h3>
                                    <br></br>
                                    <table style={{border: '1px solid black', width: '100%'}}>
                                        <thead>
                                        <th style={{textAlign: 'center', borderBottom: '1px solid black', borderRight: '1px solid black'}}>Gut gemacht</th>
                                        <th style={{textAlign: 'center', borderBottom: '1px solid black'}}>Zu verbessern</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{borderRight: '1px solid black', padding: '5px', width: '50%'}}>
                                                    <ol>
                                                    {
                                                        child.wellDone.map((point,key) => {
                                                            return <li key={key}>{point}</li>
                                                        })
                                                    }
                                                    </ol>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                    <ol>
                                                    {
                                                        child.toImprove.map((point,key) => {
                                                            return <li key={key}>{point}</li>
                                                        })
                                                    }
                                                    </ol>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            })
                        }
                        <div>
                            <p></p>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChild}>Schließen</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}