import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { setEnvironmentData } from "worker_threads";
import CheckAnmeldung from "../componets/Anmeldung/CheckAnmeldung";
import Datenschutz from "../componets/Anmeldung/Datenschutz";
import { Child } from "../componets/Nikolaus/Child";
import NikolausDaten from "../componets/Nikolaus/NikolausDaten";
import { UpdateNikolausBooking } from "../componets/Nikolaus/UpdateNikolausBooking";
import TextSplitter from "../componets/TextSplitter";
import {
  ErrorNotification,
  SuccessNotification,
} from "../configuration/notifications";
import { useAuth } from "../context/AuthContext";
import { useNikolausaktionBooking } from "../context/NikolausaktionBookingContext";
import NikolausaktionContext, {
  useNikolausaktion,
} from "../context/NikolausaktionContext";
import {
  getNikolausaktionBookingDTO,
  getNikolausaktionBookingFormState,
} from "../mapper/NikolausaktionBookingMaper";
import {
  Meeting,
  NikolausaktionBookingDTO,
  NikolausBookingFormState,
} from "../models";
import { NikolausChild } from "../models";
import { validateBooking } from "../validator/NikolausaktionBookingValidator";

export const Nikolaus = () => {
  const auth = useAuth();
  const [newBooking, setNewBooking] = useState<NikolausBookingFormState>({
    email: auth.currentUser?.email,
  } as NikolausBookingFormState);
  const [bookings, setBookings] = useState<NikolausaktionBookingDTO[]>([]);
  const [children, setChildren] = useState<NikolausChild[]>([]);

  const [bookingActive, setBookingActive] = useState(false);
  const [dates, setDates] = useState<string[]>([]);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [timeSpan, setTimeSpan] = useState<Meeting[]>([]);
  const [checkBoxChecked, setCheckBoxChecked] = useState<boolean>(false);

  const nikolausBookingContext = useNikolausaktionBooking();

  const { getNikolausaktion, getAvailableMeetings, loading, setLoading } =
    useNikolausaktion();

  useEffect(() => {
    if (auth.currentUser !== null && auth.currentUser !== undefined) {
      getAvailableMeetings()
        .then((response) => {
          let temp = groupBy(response, (x) => x.date);
          let keyArray = [] as string[];
          for (var key of temp.keys()) {
            keyArray.push(key!);
          }
          setDates(keyArray);
          setMeetings(response);
        })
        .catch((reason) => {
          ErrorNotification(reason.statusCode, reason.message);
        })
        .finally(() => {
          setLoading(false);
          nikolausBookingContext.setLoading(false)
        });
      nikolausBookingContext
        .getNikolausaktionBookingsForUser()
        .then((response) => {
          if (response.length > 0) {
            setBookings(response);
          }
        })
        .catch((reason) => {
          ErrorNotification(reason.statusCode, reason.message);
        })
        .finally(() => {
          nikolausBookingContext.setLoading(false);
        });
    }
    getNikolausaktion().then((response) => {
      setBookingActive(response.active);
    }).finally(() => {
      setLoading(false)
      nikolausBookingContext.setLoading(false)
    });
  }, []);

  function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
      var key = grouper(item);
      if (!store.has(key)) {
        store.set(key, [item]);
      } else {
        store.get(key)!.push(item);
      }
      return store;
    }, new Map<K, V[]>());
  }

  const setFilteredTimeSpans = (date: string) => {
    var formatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    } as Intl.DateTimeFormatOptions;
    let tempMap = groupBy(meetings, (x) => x.date);
    setTimeSpan(Array.from(tempMap.get(date) as Meeting[]));
  };

  const addChild = () => {
    setChildren([
      ...children,
      {
        name: "",
        age: 0,
        wellDone: [] as String[],
        toImprove: [] as String[],
      } as NikolausChild,
    ]);
  };

  const addPointToWellDone = (keyOfChild: number) => {
    setChildren(
      children.map((child, key) =>
        key === keyOfChild
          ? { ...child, wellDone: [...child.wellDone, ""] }
          : child
      )
    );
  };

  const addPointToToImprove = (keyOfChild: number) => {
    setChildren(
      children.map((child, key) =>
        key === keyOfChild
          ? { ...child, toImprove: [...child.toImprove, ""] }
          : child
      )
    );
  };

  const deleteNikolausaktionBooking = (id: string) => {
    nikolausBookingContext
      .deleteNikolausaktionBooking(id)
      .then(() => {
        setBookings(bookings.filter((booking) => booking.id !== id));
        SuccessNotification("Die Nikolausbuchung wurde erfolgreich storniert!");
        getAvailableMeetings()
          .then((response) => {
            let temp = groupBy(response, (x) => x.date);
            let keyArray = [] as string[];
            for (var key of temp.keys()) {
              keyArray.push(key!);
            }
            setDates(keyArray);
            setMeetings(response);
          })
          .catch((reason) => {
            ErrorNotification(reason.statusCode, reason.message);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .finally(() => {
        nikolausBookingContext.setLoading(false);
      });
  };

  const updateBooking = (
    booking: NikolausBookingFormState,
    children: NikolausChild[],
    id: string
  ) => {
    if (validateBooking(booking, children)) {
      let nikolausBooking = getNikolausaktionBookingDTO(booking, children);
      nikolausBookingContext
        .updateNikolausaktionBooking(nikolausBooking)
        .then((result) => {
          setBookings((prevBookings) => {
            return prevBookings.map((booking) =>
              booking.id === result.id ? result : booking
            );
          });
          getAvailableMeetings()
            .then((response) => {
              let temp = groupBy(response, (x) => x.date);
              let keyArray = [] as string[];
              for (var key of temp.keys()) {
                keyArray.push(key!);
              }
              setDates(keyArray);
              setMeetings(response);
            })
            .catch((reason) => {
              ErrorNotification(reason.statusCode, reason.message);
            })
            .finally(() => {
              setLoading(false);
            });
          setNewBooking({});
          SuccessNotification(
            "Die Nikolausbuchung wurde erfolgreich aktualiesiert!"
          );
        })
        .catch((reason) => {
          ErrorNotification(reason.statusCode, reason.message);
        })
        .finally(() => {
          nikolausBookingContext.setLoading(false);
        });
    }
  };

  const bookNikolaus = () => {
    if (validateBooking(newBooking, children)) {
      let nikolausBooking = getNikolausaktionBookingDTO(newBooking, children);
      nikolausBookingContext
        .createNikolausaktionBooking(nikolausBooking)
        .then((result) => {
          getAvailableMeetings()
            .then((response) => {
              let temp = groupBy(response, (x) => x.date);
              let keyArray = [] as string[];
              for (var key of temp.keys()) {
                keyArray.push(key!);
              }
              setDates(keyArray);
              setMeetings(response);
            })
            .catch((reason) => {
              ErrorNotification(reason.statusCode, reason.message);
            })
            .finally(() => {
              setLoading(false);
            });
          setBookings([...bookings, result] as NikolausaktionBookingDTO[]);
          setNewBooking({});
          setChildren([]);
          SuccessNotification(
            "Die Nikolausbuchung wurde erfolgreich gespeichert!"
          );
        })
        .catch((reason) => {
          ErrorNotification(reason.statusCode, reason.message);
        })
        .finally(() => {
          nikolausBookingContext.setLoading(false);
        });
    }
  };

  return (
    <>
      <div>
        <Container sx={{ paddingBottom: "4%" }}>
          <br></br>
          <br></br>
          <br></br>

          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h1>Nikolausaktion der Pfarrjugend Neusäß</h1>
            <p>
              Wie jedes Jahr bietet die Pfarrjugend Neusäß auch dieses Jahr die
              Nikolausaktion für Sie und Ihre Kids an. Um den Nikolaus zu sich
              einzuladen, müssen Sie ein Benutzerkonto (falls nicht schon
              vorhanden) anlegen und den unteren Anmeldungsbogen ausfüllen.
              Jeder Schritt ist dabei erklärt und die Anmeldung lässt sich erst
              abschließen, wenn alle Angaben getätigt sind. Nachdem Sie Ihren
              Anmeldungsbogen abgeschickt haben, erhalten Sie eine
              Bestätigungsemail und der Nikolaus trägt diesen Termin VERBINDLICH
              in seinen Kalender ein. Sollten Sie sich erfolgreich angemeldet
              haben, wird der Nikolaus in Begleitung eines Engels oder eines
              zahmen Knecht Ruprechts zum vereinbarten Termin bei Ihnen
              erscheinen. Bitte beachten Sie, dass die genaue Uhrzeit je nach
              Situation um bis zu einer viertel Stunde variieren kann. Soll der
              Nikolaus Ihren Kindern eine Kleinigkeit überreichen platzieren Sie
              diese bitte mit NAMEN GEKENNZEICHNET an ihrer Haustür. Nachdem der
              Nikolaus aus seinem Buch alle Nettigkeiten und
              Verbesserungsvorschläge zur Ihren Kindern verlesen hat, werden von
              ihm die Presente verteilt. Abschließend ist der Nikolaus mit einer
              Spende ab 30 Euro zu belohnen ;) Wir freuen uns auf den Besuch bei
              Ihnen zu Hause. 
            </p>
            <p>Mit freundlichen Grüßen</p>
            <p>Der Nikolaus der Pfarrjugend Neusäß</p>
          </Container>
          <TextSplitter />
          {bookings !== undefined &&
          bookings !== null &&
          bookings.length > 0 
           ? (
            bookings.map((booking, index) => {
              let formState = getNikolausaktionBookingFormState(booking);
              return (
                <>
                  <br></br>
                  {dates !== undefined &&
                  meetings !== undefined &&
                  dates.length > 0 &&
                  meetings.length > 0 ? ( 
                    <>
                      <h4>
                        Hier können Sie Ihre aktuellen Buchungen einsehen. Wenn
                        Sie eine Änderung vornehmen, drücken Sie unbedingt auf
                        "Nikolausbuchung aktualisieren"!!!. Sie können, so lange
                        die Anmeldung noch freigeschaltet ist, die Buchungen
                        bearbeiten oder stornieren. Danach ist dies nicht mehr
                        möglich!!! Bei Fragen wenden Sie sich an
                        website@pfarrjugendneusaess.de{" "}
                      </h4>
                      <UpdateNikolausBooking
                        booking={formState}
                        children={booking.children}
                        index={index}
                        key={index}
                        bookingId={booking.id!}
                        dates={dates}
                        meetings={meetings}
                        timeSpan={booking.timeSpan}
                        handleDeleteBooking={deleteNikolausaktionBooking}
                        update={updateBooking}
                      ></UpdateNikolausBooking>
                    </>
                  ) : null}
                </>
              );
            })
          ) : (
            null
          )}
          {bookingActive == true ? (
            auth.currentUser === undefined || auth.currentUser == null ? (
              <CheckAnmeldung />
            ) : nikolausBookingContext.loading === true ? (
              <CircularProgress />
            ) : (
              <Container>
                <h2 style={{ textAlign: "center" }}>Anmeldung</h2>
                <br></br>
                <h3>Elternteil</h3>
                <NikolausDaten
                  setBooking={setNewBooking}
                  booking={newBooking}
                  disableButtons={false}
                />
                <br></br>
                <hr></hr>
                <br></br>
                <h3 style={{ textAlign: "center" }}>
                  An welchem Tag soll der Nikolaus zu Ihnen kommen?
                </h3>
                <br></br>
                <Grid container xs={12}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      padding: "5%",
                    }}
                  >
                    <InputLabel id="date">Datum</InputLabel>
                    <Select
                      labelId="date"
                      id="dateId"
                      label="Datum"
                      fullWidth
                      onChange={(value) => {
                        if (!!value) {
                          setNewBooking({
                            ...newBooking,
                            date: new Date(
                              value.target.value as string
                            ).toLocaleDateString(),
                          });
                          setFilteredTimeSpans(value.target.value as string);
                        }
                      }}
                    >
                      {dates.map((date) => {
                        return <MenuItem value={date}>{date}</MenuItem>;
                      })}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      padding: "5%",
                    }}
                  >
                    <InputLabel id="time">Uhrzeit</InputLabel>
                    <Select
                      labelId="time"
                      id="timeId"
                      label="Uhrzeit"
                      onChange={(value) => {
                        if (!!value) {
                          setNewBooking({
                            ...newBooking,
                            time: value.target.value as string,
                          });
                        }
                      }}
                      fullWidth
                    >
                      {timeSpan.length > 0 ? (
                        timeSpan.map((text) => {
                          return (
                            <MenuItem value={text.id}>{text.timeSpan}</MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem></MenuItem>
                      )}
                    </Select>
                  </Grid>
                </Grid>
                <br></br>
                <hr></hr>
                <Container sx={{ textAlign: "center" }}>
                  <h3>Informationen über die Kinder für das goldene Buch</h3>
                  <p>
                    Fügen Sie Informationen ihrer Kinder hinzu! Was haben sie im
                    letzten Jahr gut gemacht und welche Dinge könnten sie
                    eventuell noch verändern? Bitte in <b>Du-Form</b> und möglichst in <b>kurzen Sätzen</b> oder <b>stichpunktartig</b>
                  </p>
                  <div>
                    <Child
                      addPointToToImprove={addPointToToImprove}
                      addPointToWellDone={addPointToWellDone}
                      children={children}
                      setChildren={setChildren}
                      disableButtons={false}
                    ></Child>
                  </div>
                </Container>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button variant="contained" onClick={addChild}>
                    Kind hinzufügen
                  </Button>
                </Box>
                <Datenschutz
                  checked={checkBoxChecked}
                  setCheckBox={setCheckBoxChecked}
                  routing={true}
                />
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    onClick={() => bookNikolaus()}
                    disabled={!checkBoxChecked}
                  >
                    Nikolaus buchen
                  </Button>
                </Box>
                <TextSplitter></TextSplitter>
              </Container>
            )
          ) : nikolausBookingContext.loading === true ? (
            <CircularProgress />
          ) : (
            <>
              <h3>
                Die Nikolausaktion ist aktuell nicht freigeschaltet! Versuchen
                Sie es später erneut
              </h3>
              <br></br>
              <br></br>
              <br></br>
            </>
          )}
          <br></br>
        </Container>
      </div>
    </>
  );
};
export default Nikolaus;
