import { AddressDTO, NikolausaktionBookingDTO, NikolausBookingFormState, NikolausChild } from "../models";

export function getNikolausaktionBookingDTO(nikolausAktionBooking: NikolausBookingFormState, children: NikolausChild[]): NikolausaktionBookingDTO {
    let dto = {} as NikolausaktionBookingDTO
    dto.firstName = nikolausAktionBooking.firstName
    dto.lastName = nikolausAktionBooking.lastName
    dto.phoneNumber = nikolausAktionBooking.phoneNumber!
    dto.meetingId = nikolausAktionBooking.time!
    dto.address = {} as AddressDTO
    dto.address.city = nikolausAktionBooking.city!
    dto.address.plz = nikolausAktionBooking.plz!
    dto.address.street = nikolausAktionBooking.street!
    dto.address.houseNumber = nikolausAktionBooking.houseNumber!
    dto.children = children
    dto.userId = nikolausAktionBooking.userId
    dto.id = nikolausAktionBooking.id
    dto.contactSource = nikolausAktionBooking.contactSource;
    return dto
}


export function getNikolausaktionBookingFormState(booking: NikolausaktionBookingDTO): NikolausBookingFormState {
    let formState = {} as NikolausBookingFormState
    formState.firstName = booking.firstName
    formState.id = booking.id
    formState.userId = booking.userId
    formState.lastName = booking.lastName
    formState.date = booking.date
    formState.phoneNumber = booking.phoneNumber
    formState.time = booking.meetingId
    formState.street = booking.address.street
    formState.houseNumber = booking.address.houseNumber
    formState.plz = booking.address.plz
    formState.city = booking.address.city
    formState.contactSource = booking.contactSource;
    return formState;
}