import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { AktionChildDTO } from "../../models"

interface Props {
    child: AktionChildDTO
    children: AktionChildDTO[]
    setChildren: Dispatch<SetStateAction<AktionChildDTO[]>>
    keyOfChild: number
    disableButtons: boolean
}

export const AktionsChild: FC<Props> = (props) => {

    const [value, setValue] = useState<Dayjs>(dayjs("01-01-2013"));

    useEffect(() => {
        if (props.child.birthday !== undefined) {
            const [day, month, year] = props.child.birthday!.split(".");
            setValue(dayjs(new Date(`${year}-${month}-${day}`)))
        }
    }, [])


    return (
        <Grid >
            <Paper sx={{ marginTop: '2%', marginLeft: '2.5%', padding: '15px' }} key={props.keyOfChild}>
                <h4 >Kind</h4>
                <Grid container spacing={3}>
                    <Grid item display={"block"} xs={12} sm={3}>
                        <FormControl fullWidth>
                            <InputLabel>Geschlecht *</InputLabel>
                            <Select
                                aria-required
                                labelId="gender"
                                disabled={props.disableButtons}
                                id="genderId"
                                label="Geschlecht"
                                value={props.child.gender}
                                required
                                onChange={(value) => {
                                    props.setChildren(props.children.map((child, key) => key === props.keyOfChild ? { ...child, gender: value.target.value } : child))
                                }}
                            >
                                <MenuItem value={"Männlich"}>männlich</MenuItem>
                                <MenuItem value={"Weiblich"}>weiblich</MenuItem>
                                <MenuItem value={"Divers"}>divers</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            required
                            id="firstName"
                            label="Vorname"
                            disabled={props.disableButtons}
                            value={props.child.firstName}
                            fullWidth
                            autoComplete="firstName"
                            variant="outlined"
                            onChange={(value) => {
                                props.setChildren(props.children.map((child, key) => key === props.keyOfChild ? { ...child, firstName: value.target.value } : child))
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="lastname"
                            disabled={props.disableButtons}
                            label="Nachname"
                            value={props.child.lastName}
                            fullWidth
                            autoComplete="family-name"
                            variant="outlined"
                            onChange={(value) => {
                                props.setChildren(props.children.map((child, key) => key === props.keyOfChild ? { ...child, lastName: value.target.value } : child))
                            }}
                        ></TextField>
                    </Grid>

                    <Grid item xs={12} textAlign={"left"}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Geburtsdatum *"
                                value={value}
                                disabled={props.disableButtons}
                                inputFormat="DD.MM.YYYY"
                                maxDate={dayjs("2014-07-27")}
                                onChange={(newValue) => {
                                    if (newValue != null) {
                                        props.setChildren(props.children.map((child, key) => key === props.keyOfChild ? { ...child, birthday: new Date(newValue!.toDate()).toLocaleDateString() } : child))
                                        setValue(newValue as Dayjs)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="allergic"
                            label="Allergien"
                            disabled={props.disableButtons}
                            value={props.child.allergica}
                            fullWidth
                            multiline
                            maxRows={4}
                            onChange={(value) => {
                                props.setChildren(props.children.map((child, key) => key === props.keyOfChild ? { ...child, allergica: value.target.value } : child))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="medic"
                            label="Medikamente"
                            fullWidth
                            disabled={props.disableButtons}
                            multiline
                            value={props.child.medics}
                            maxRows={4}
                            onChange={(value) => {
                                props.setChildren(props.children.map((child, key) => key === props.keyOfChild ? { ...child, medics: value.target.value } : child))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="otherRemarks"
                            label="sonstige Bemerkungen"
                            placeholder="Kann nicht schwimmen,..."
                            fullWidth
                            disabled={props.disableButtons}
                            value={props.child.otherRemarks}
                            multiline
                            maxRows={4}
                            onChange={(value) => {
                                props.setChildren(props.children.map((child, key) => key === props.keyOfChild ? { ...child, otherRemarks: value.target.value } : child))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <p>Trifft einer der folgenden Punkte auf ihr Kind zu ?</p>
                        <Select
                            id="foodSpecials"
                            placeholder=""
                            fullWidth
                            disabled={props.disableButtons}
                            value={props.child.intolerance}
                            defaultValue=""
                            maxRows={4}
                            onChange={(value) => {
                                props.setChildren(props.children.map((child, key) => key === props.keyOfChild ? { ...child, intolerance: value.target.value } : child))
                            }}
                        >
                            <MenuItem value={"Laktoseintoleranz"}>Laktoseintoleranz</MenuItem>
                            <MenuItem value={"Zöliakie"}>Zöliakie (Glutenintoleranz)</MenuItem>
                            <MenuItem value={"Vegan"}>Vegan</MenuItem>
                            <MenuItem value={"Vegetarisch"}>Vegetarisch</MenuItem>
                            <MenuItem value={"Honig"}>Honig</MenuItem>
                            <MenuItem value={"Nüsse"}>Nüsse</MenuItem>
                            <MenuItem value={"Nichts davon"}>Nichts davon</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Button variant="contained" sx={{ margin: '20px' }} onClick={() => props.setChildren(props.children.filter((child, key) => key !== props.keyOfChild))} disabled={props.disableButtons}>Kind löschen</Button>
            </Paper>
        </Grid>
    )
}
